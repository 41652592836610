import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { theme } from '../styles'
import { Layout, Wrapper, SEO } from '../components'
import website from '../../config/website'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'
import ReCAPTCHA from 'react-google-recaptcha'

const Hero = styled.div`
  background-color: ${theme.colors.white};
  padding-top: ${theme.spacing(24)};
  padding-bottom: ${theme.spacing(6)};
  padding: ${theme.spacing(16)} ${theme.spacing(3)} ${theme.spacing(6)};
  max-width: ${theme.maxWidth};
  margin: 0 auto;
  text-align: center;
`

const Headline = styled.h2`
  font-size: 4rem;
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 3.5rem;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 3rem;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    font-size: 2.5rem;
  }
`

const SubHeadline = styled.p`
  font-size: 3.5rem;
  @media (max-width: ${theme.breakpoints.ms}) {
    font-size: 2.9rem;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 2.4rem;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    font-size: 2.2rem;
  }
`

const Button = styled.button`
  background-color: rgb(252, 241, 19);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-family: TeleGroteskNext-Bold;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(252, 241, 19);
  border-image: initial;
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  margin: ${theme.spacing(2)} 0px 0px 0px;
  :hover,
  :focus {
    color: rgb(255, 255, 255);
    background-color: black;
    border-color: black;
    cursor: pointer;
    outline: none;
  }
`
const Label = styled.label`
  width: ${props => (props.flex ? 'auto' : '100%')};
  font-family: TeleGroteskNext-Bold;
`
const Input = styled.input`
  background-color: white;
  letter-spacing: 0.5px;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: ${theme.colors.greyLight};
  border-image: initial;
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  margin: 0px 16px 0px 0px;
  width: 100%;
  font-family: TeleGroteskNext-Regular;
  :focus {
    border-color: ${theme.colors.black};
    outline: none;
  }
`

const HiddenCheckbox = styled.input`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

HiddenCheckbox.defaultProps = { type: 'checkbox' }

const Icon = styled.svg`
  fill: none;
  stroke: ${theme.colors.black};
  stroke-width: 3px;
`

const StyledCheckbox = styled.div`
  flex-shrink: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${props => (props.checked ? theme.colors.white : theme.colors.white)};
  margin-left: 16px;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: ${theme.colors.greyLight};
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    //box-shadow: 0 0 0 3px pink;
    border-color: ${theme.colors.black};
  }
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
    vertical-align: bottom;
  }
  :hover {
    cursor: pointer;
  }
`
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Privacy = styled.div`
  margin-bottom: ${theme.spacing(2)};
`

const Flex = styled.div`
  display: flex;
`

const Checkbox = ({ checked, className, handler, ...props }) => (
  <React.Fragment>
    <HiddenCheckbox checked={checked} onChange={handler} {...props} />
    <StyledCheckbox checked={checked} onClick={handler}>
      <Icon viewBox="0 0 28 28">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </React.Fragment>
)

const Textarea = styled.textarea`
  background-color: white;
  letter-spacing: 0.5px;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: ${theme.colors.greyLight};
  border-image: initial;
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  margin: 0px 16px 0px 0px;
  width: 100%;
  font-family: TeleGroteskNext-Regular;
  :focus {
    border-color: ${theme.colors.black};
    outline: none;
  }
`

const PageWrapper = Wrapper.withComponent('main')

const Content = styled.div`
  max-width: ${theme.maxWidthText};
  margin: ${theme.spacing(6)} auto 0;
  padding-bottom: ${theme.spacing(6)};
  padding: 6rem 0;
  @media (max-width: ${theme.breakpoints.l}) {
  }
  @media (max-width: ${theme.breakpoints.m}) {
    padding: 4rem 0;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    padding: 2rem 0;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
  }
`

function ContactFormPage({ location }) {
  const [privacyConsent, setPrivacyConsent] = useState(false)

  return (
    <Layout customSEO>
      <Amplitude
        eventProperties={{
          scope: ['form'],
          title: 'Contacta con nosotros',
          url: location.pathname,
        }}
      >
        <LogOnMount eventType="form view" />
        <SEO
          title={`Contacta con nosotros | ${website.titleAlt}`}
          pathname={location.pathname}
          desc="Formulario de contacto"
        />
        <Hero>
          <Headline>Contacta con nosotros</Headline>
          <SubHeadline>Rellena el formulario</SubHeadline>
        </Hero>
        <PageWrapper>
          <Content>
            <Amplitude>
              {({ instrument }) => (
                <form
                  name="contact"
                  method="POST"
                  action={`${location.pathname}/thankyou`}
                  data-netlify="true"
                  data-netlify-recaptcha="true"
                  onSubmit={instrument('form submit')}
                >
                  <input type="hidden" name="form-name" value="contact" />

                  <p>
                    <Label htmlFor="name">Nombre y apellidos:</Label>
                    <Input type="text" id="name" name="name" required />
                  </p>
                  <p>
                    <Label htmlFor="email">Correo electrónico:</Label>
                    <Input type="email" id="email" name="email" required />
                  </p>
                  <p>
                    <Label htmlFor="org">Organización:</Label>
                    <Input type="text" id="org" name="org" required />
                  </p>
                  <p>
                    <Label htmlFor="message">Mensaje:</Label>
                    <Textarea id="message" name="message" required />
                  </p>

                  <Privacy>
                    <Flex>
                      <Label flex htmlFor="privacy-consent">
                        He leído y acepto la política de tratamiento de datos personales:{` `}
                      </Label>
                      <Checkbox
                        name="privacy-consent"
                        id="privacy-consent"
                        checked={privacyConsent}
                        required
                        className="checkbox"
                        handler={() => setPrivacyConsent(!privacyConsent)}
                      />
                    </Flex>
                    <em>
                      <a href="#">¿Cómo vamos a tratar estos datos?</a>
                    </em>
                  </Privacy>

                  <ReCAPTCHA sitekey={website.siteRecaptchaKey} />

                  <Button type="submit">Enviar</Button>
                </form>
              )}
            </Amplitude>
          </Content>
        </PageWrapper>
      </Amplitude>
    </Layout>
  )
}

export default ContactFormPage

ContactFormPage.propTypes = {
  location: PropTypes.object.isRequired,
}
